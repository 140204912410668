export function getDisplayName(data: {
  name: string;
  position: number;
  orderReceived: { moneyNumber?: string; moneyAddressName?: string };
}): string {
  return `${data.orderReceived.moneyNumber}-${data.position} ${data.orderReceived.moneyAddressName} - ${data.name}`;
}

export function toOption(data: {
  name: string;
  id: string;
  position: number;
  orderReceived: { moneyNumber?: string; moneyAddressName?: string };
}) {
  return {
    label: getDisplayName(data),
    value: data.id,
  };
}
