import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { DATETIME_FORMAT, FIELD_DATE_IS_INVALID } from '@engined/client/locales.js';
import { TextFieldProps } from '@mui/material';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import React from 'react';
import { Control, useController } from 'react-hook-form';

interface OwnProps {
  name: string;
  onBlur?: TextFieldProps['onBlur'];
  error?: TextFieldProps['error'];
  helperText?: TextFieldProps['helperText'];
  inputProps?: TextFieldProps['inputProps'];
  variant?: TextFieldProps['variant'];
  required?: TextFieldProps['required'];
  fullWidth?: TextFieldProps['fullWidth'];
  onFocus?: TextFieldProps['onFocus'];
  control?: Control;
}

export type Props = OwnProps & OptionalBy<DateTimePickerProps<Date>, 'value' | 'onChange'>;

const DateTimeField: React.FunctionComponent<Props> = ({
  name,
  onBlur,
  onChange,
  error,
  helperText,
  variant,
  required,
  fullWidth,
  onFocus,
  control,
  ...rest
}) => {
  const { t } = useLocale();

  const validate = (value: Date) => {
    if (value && isNaN(value.getTime())) {
      return t(FIELD_DATE_IS_INVALID);
    }
  };

  const { field, fieldState } = useController({
    name,
    control,
    rules: {
      validate,
    },
  });

  const onBlurCallback: Props['onBlur'] = (event) => {
    field.onBlur();
    if (onBlur) {
      onBlur(event);
    }
  };

  const onChangeCallback: Props['onChange'] = (value, ...rest) => {
    field.onChange(value);
    if (onChange) {
      onChange(value, ...rest);
    }
  };

  const showError = !!fieldState.error;
  const helperTextOrError = (showError && fieldState.error.message) || helperText;

  return (
    <DateTimePicker
      onChange={onChangeCallback}
      value={field.value}
      format={t(DATETIME_FORMAT)}
      ampm={false}
      slotProps={{
        textField: {
          id: name,
          variant,
          onBlur: onBlurCallback,
          inputProps: { name },
          error: error || showError,
          helperText: helperTextOrError,
          required,
          fullWidth,
        },
      }}
      inputRef={(r) => {
        field.ref(r);
      }}
      {...rest}
    />
  );
};

DateTimeField.displayName = 'DateTimeField';

export default React.memo<Props>(DateTimeField);
