export function getDisplayName(data: { name: string }): string {
  return data.name;
}

interface Data {
  id: string;
  name: string;
  color?: string;
}

export function toOption<TData extends Data>(data: TData) {
  return {
    value: data.id,
    label: getDisplayName(data),
    data,
  };
}
