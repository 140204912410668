import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import useMounted from '@engined/client/hooks/useMounted.js';
import { useState, MouseEvent } from 'react';

interface Return<T> {
  open: boolean;
  value: T;
  onClose();
  onOpen(event?: MouseEvent<HTMLElement>, value?: T);
  setOpen(value: T);
}

type StateValue<T> = T | boolean | null;

export default function useDialog<T = boolean>(initialValue: StateValue<T>): Return<T> {
  const [open, setOpen] = useState<StateValue<T>>(initialValue);
  const mounted = useMounted();

  const onClose = useEventCallback<Return<StateValue<T>>['onClose']>(() => {
    if (mounted.current) {
      setOpen(null);
    }
  });

  const onOpen = useEventCallback<Return<StateValue<T>>['onOpen']>((event: MouseEvent<HTMLElement>, value: T) => {
    setOpen(value ?? true);
  });

  return {
    open: !!open,
    value: typeof open === 'boolean' ? null : open,
    onClose,
    onOpen,
    setOpen,
  };
}
