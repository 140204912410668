import { PhaseField_Load } from '@asaprint/asap/components/forms/fields/PhaseField.graphql';
import { PhaseField_LoadQuery } from '@asaprint/asap/schema.client.types.js';
import { toOption } from '@asaprint/common/helpers/Phase.js';
import { Option } from '@engined/client/components/forms/fields/AutocompleteField.js';
import RelationField, { Props as RelationFieldProps } from '@engined/client/components/forms/fields/RelationField.js';
import { Box } from '@mui/material';
import React, { useCallback } from 'react';

interface OwnProps {}

type SpecificRelationFieldProps = RelationFieldProps<Option, any, any, any>;
type Props = OwnProps & Omit<SpecificRelationFieldProps, 'dataToOptions' | 'query' | 'variables'>;

type PhaseOption = Option<{
  id: string;
  color: string;
  group: {
    id: string;
    name: string;
  };
}>;

const phasesToOptions = (data: PhaseField_LoadQuery): PhaseOption[] =>
  data?.phaseGroups?.edges.reduce((acc, cur) => {
    return acc.concat(
      cur.node.phases.map((phase) =>
        toOption({
          ...phase,
          group: cur.node,
        }),
      ),
    );
  }, []);

const PhaseField: React.FunctionComponent<Props> = ({ name, ...rest }) => {
  const renderOption = useCallback<SpecificRelationFieldProps['renderOption']>((props, option: PhaseOption) => {
    return (
      <Box component="li" borderLeft={8} borderColor={`#${option.data.color}`} {...props}>
        {option.label}
      </Box>
    );
  }, []);

  return (
    <RelationField
      {...rest}
      query={PhaseField_Load}
      dataToOptions={phasesToOptions}
      name={name}
      renderOption={renderOption}
      groupBy={(option: PhaseOption) => option.data.group?.name ?? ''}
    />
  );
};

PhaseField.displayName = 'PhaseField';

export default React.memo<Props>(PhaseField);
