import OrderReceivedField from '@asaprint/asap/components/forms/fields/OrderReceivedField.js';
import PhaseField from '@asaprint/asap/components/forms/fields/PhaseField.js';
import ProductField from '@asaprint/asap/components/forms/fields/ProductField.js';
import UserField from '@asaprint/asap/components/forms/fields/UserField.js';
import { OrderReceivedField_OrderFragment, ProductField_ProductFragment } from '@asaprint/asap/schema.client.types.js';
import { phaseOptions } from '@asaprint/common/constants/OrderReceived.js';
import { Option } from '@engined/client/components/forms/fields/AutocompleteField.js';
import DateTimeField from '@engined/client/components/forms/fields/DateTimeField.js';
import HideableDependentField from '@engined/client/components/forms/fields/HideableDependentField.js';
import SelectField, { Props as SelectFieldProps } from '@engined/client/components/forms/fields/SelectField.js';
import TextField from '@engined/client/components/forms/fields/TextField.js';
import { FormErrors } from '@engined/client/hooks/useFormResolver.js';
import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

interface OwnProps {
  className?: string;
  showType?: boolean;
  showUser?: boolean;
  row?: boolean;
  prefix?: string;
}

export interface FormValues {
  id?: string;
  updatedAt?: string;
  type: 'none' | 'product' | 'orderReceived';
  startAt: Date;
  endAt: Date;
  product: Option<ProductField_ProductFragment>;
  phase: Option;
  orderReceived: Option<OrderReceivedField_OrderFragment>;
  orderReceivedPhase: string;
  user: Option;
  note: string;
}

const typesOptions = [
  { value: 'product', label: 'produktu' },
  { value: 'orderReceived', label: 'objednávke' },
  { value: 'none', label: 'internej úlohe' },
];

type Props = OwnProps;

const TimeEntryForm: React.FunctionComponent<Props> = ({ showType = false, showUser = false, prefix = '' }) => {
  return (
    <Grid container spacing={2}>
      {showUser && (
        <Grid item xs={12} md={12}>
          <UserField name={`${prefix}user`} inputLabel="Pracovník" active />
        </Grid>
      )}
      {showType && (
        <>
          <Grid item xs={12} md={12}>
            <SelectField name={`${prefix}type`} options={typesOptions} label="Časový záznam k" fullWidth />
          </Grid>
          <HideableDependentField dependsOn={`${prefix}type`} value="orderReceived">
            <Grid item xs={12} md={12}>
              <OrderReceivedField name={`${prefix}orderReceived`} inputLabel="Objednávka" fullWidth />
            </Grid>
            <Grid item xs={12} md={12}>
              <OrderReceivedPhaseField name="orderReceivedPhase" label="Fáza" fullWidth />
            </Grid>
          </HideableDependentField>
          <HideableDependentField dependsOn={`${prefix}type`} value="product">
            <Grid item xs={12} md={12}>
              <ProductField name={`${prefix}product`} inputLabel="Produkt" fullWidth />
            </Grid>
          </HideableDependentField>
        </>
      )}
      <HideableDependentField dependsOn={`${prefix}type`} value="product">
        <Grid item xs={12} md={12}>
          <PhaseField name={`${prefix}phase`} inputLabel="Fáza" fullWidth />
        </Grid>
      </HideableDependentField>
      <Grid item xs={12} md={12}>
        <DateTimeField name={`${prefix}startAt`} label="Začiatok" fullWidth />
      </Grid>
      <Grid item xs={12} md={12}>
        <DateTimeField name={`${prefix}endAt`} label="Koniec" fullWidth />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField name={`${prefix}note`} label="Poznámka" multiline fullWidth />
      </Grid>
    </Grid>
  );
};

TimeEntryForm.displayName = 'TimeEntryForm';

export default TimeEntryForm;

export function validate(values: FormValues): FormErrors<FormValues> {
  const errors: FormErrors<FormValues> = {};

  if (values.type === 'product') {
    if (!values.product) {
      errors.product = 'Produkt je povinný';
    }
  } else if (values.type === 'orderReceived') {
    if (!values.orderReceived) {
      errors.orderReceived = 'Objednávka je povinná';
    }
    if (!values.orderReceivedPhase) {
      errors.orderReceivedPhase = 'Fáza je povinná';
    }
  } else if (!values.type) {
    errors.type = 'Toto pole je povinné';
  }

  if (!values.user) {
    errors.user = 'Pracovník je povinný';
  }

  if (!values.startAt) {
    errors.startAt = 'Začiatok je povinný';
  }

  if (!values.endAt) {
    errors.endAt = 'Koniec je povinný';
  }

  if (values.startAt && values.endAt && values.startAt > values.endAt) {
    errors.endAt = 'Koniec je pred začiatkom';
  }

  return errors;
}

let OrderReceivedPhaseField: React.FC<Omit<SelectFieldProps, 'options'>> = (props) => {
  const { setValue } = useFormContext<FormValues>();
  const orderReceived = useWatch<FormValues, 'orderReceived'>({ name: 'orderReceived' });
  const orderReceivedPhase = useWatch<FormValues, 'orderReceivedPhase'>({ name: 'orderReceivedPhase' });
  useEffect(() => {
    if (orderReceived?.value && orderReceivedPhase !== orderReceived.value) {
      setValue('orderReceivedPhase', orderReceived.data.phase);
    }
  }, [orderReceived, orderReceivedPhase, setValue]);

  return <SelectField options={phaseOptions} {...props} />;
};
OrderReceivedPhaseField = React.memo(OrderReceivedPhaseField);
