import { Role } from '@asaprint/common/access.js';
import { Option } from '@engined/client/components/forms/fields/AutocompleteField.js';
import RelationField, { Props as RelationFieldProps } from '@engined/client/components/forms/fields/RelationField.js';
import { UserField_Load } from '@asaprint/asap/components/forms/fields/UserField.graphql';
import { UserField_LoadQuery } from '@asaprint/asap/schema.client.types.js';
import { toOption as userToOption } from '@asaprint/common/helpers/User.js';
import React, { useMemo } from 'react';

interface OwnProps {
  className?: string;
  name: string;
  active?: boolean;
  roles?: Role[];
}

type SpecificRelationFieldProps = Omit<
  RelationFieldProps<Option, any, any, any>,
  'dataToOptions' | 'query' | 'variables'
>;
export type Props = OwnProps & SpecificRelationFieldProps;

const ownerDataToOptions = (data: UserField_LoadQuery): Option[] =>
  data.users.edges.map((edge) => userToOption(edge.node));

const UserField: React.FunctionComponent<Props> = ({ name, active = undefined, roles = undefined, ...rest }) => {
  const variables = useMemo(
    () => ({
      active,
      roles,
    }),
    [active, roles],
  );

  return (
    <RelationField
      {...rest}
      name={name}
      query={UserField_Load}
      dataToOptions={ownerDataToOptions}
      variables={variables}
    />
  );
};

UserField.displayName = 'FestivalUserField';

export default React.memo(UserField);
