import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { getLogger } from '@engined/core/services/logger.js';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@mui/material';
import React from 'react';
import { Control, useController } from 'react-hook-form';

const logger = getLogger('components/forms/fields/SelectField');

export interface Option {
  label: React.ReactNode;
  value: string;
}

interface OwnProps {
  name: string;
  options: Option[];
  helperText?: React.ReactNode;
  control?: Control;
}

export type Props = OwnProps & Omit<MuiSelectProps, 'error'>;

function SelectField({ name, onBlur, onChange, multiple, control, ...rest }: Props) {
  const { field, fieldState } = useController({
    control,
    name,
  });

  if (process.env.NODE_ENV !== 'production') {
    if (multiple && !Array.isArray(field.value)) {
      logger.warn(`value for ${field.name} is not an array, this can caused unexpected behaviour`);
    }
  }

  const onBlurCallback = useEventCallback<MuiSelectProps['onBlur']>((event) => {
    field.onBlur();
    if (onBlur) {
      onBlur(event);
    }
  });

  const onChangeCallback = useEventCallback<MuiSelectProps['onChange']>((event, value: any, ...rest) => {
    field.onChange(event);
    if (onChange) {
      onChange(event, value, ...rest);
    }
  });

  const showError = !!fieldState.error;
  const inputLabelId = rest.label && rest.id ? `${rest.id}-label` : undefined;

  return (
    <SelectFieldInner
      onBlur={onBlurCallback}
      onChange={onChangeCallback}
      name={name}
      value={field.value}
      inputLabelId={inputLabelId}
      multiple={multiple}
      error={showError ? fieldState.error.message : null}
      inputRef={field.ref}
      {...rest}
    />
  );
}

SelectField.displayName = 'SelectField';

export default React.memo(SelectField);

interface SelectFieldInnerOwnProps {
  inputLabelId: string;
  error: string;
}

type SelectFieldInnerProps = SelectFieldInnerOwnProps & Omit<Props, 'error'>;

let SelectFieldInner: React.FC<SelectFieldInnerProps> = ({ inputLabelId, error, options, helperText, ...rest }) => (
  <FormControl
    fullWidth={rest.fullWidth}
    error={Boolean(error)}
    margin={rest.margin}
    disabled={rest.disabled}
    variant={rest.variant}
    required={rest.required}
  >
    <InputLabel
      htmlFor={rest.id}
      id={inputLabelId}
      required={rest.required}
      disabled={rest.disabled}
      variant={rest.variant}
    >
      {rest.label}
    </InputLabel>
    <MuiSelect
      labelId={inputLabelId}
      onBlur={rest.onBlur}
      onChange={rest.onChange}
      value={rest.value}
      multiple={rest.multiple}
      name={rest.name}
      id={rest.id}
      displayEmpty={rest.displayEmpty}
      {...rest}
    >
      {options.map((opt) => (
        <MenuItem key={opt.value} value={opt.value}>
          {opt.value === '' ? <em>{opt.label}</em> : opt.label}
        </MenuItem>
      ))}
    </MuiSelect>
    {helperText && <FormHelperText>{helperText}</FormHelperText>}
    {error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
);

SelectFieldInner.displayName = 'SelectFieldInner';
SelectFieldInner = React.memo(SelectFieldInner);
