import { OrderReceivedField_Load } from '@asaprint/asap/components/forms/fields/OrderReceivedField.graphql';
import { OrderReceivedField_LoadQuery } from '@asaprint/asap/schema.client.types.js';
import { toOption } from '@asaprint/common/helpers/OrderReceived.js';
import { Option } from '@engined/client/components/forms/fields/AutocompleteField.js';
import RelationField, { Props as RelationFieldProps } from '@engined/client/components/forms/fields/RelationField.js';
import React from 'react';

interface OwnProps {}

type Props = OwnProps & Omit<RelationFieldProps<Option, any, any, any>, 'dataToOptions' | 'query' | 'variables'>;

const dataToOptions = (data: OrderReceivedField_LoadQuery) =>
  data?.ordersReceived?.edges.map((edge) => toOption(edge.node));

const OrderReceivedField: React.FunctionComponent<Props> = ({ name, ...rest }) => {
  return <RelationField {...rest} query={OrderReceivedField_Load} dataToOptions={dataToOptions} name={name} />;
};

OrderReceivedField.displayName = 'OrderReceivedField';

export default React.memo<Props>(OrderReceivedField);
