import React from 'react';
import { useWatch } from 'react-hook-form';

interface OwnProps {
  dependsOn: string;
  value?: string | boolean | ((v) => boolean);
  not?: boolean;
  children: React.ReactNode;
}

type Props = OwnProps;

const compareValues = (value, fieldValue): boolean => {
  if (typeof value === 'function') {
    return value(fieldValue);
  } else if (typeof value === 'boolean' && typeof fieldValue === 'string') {
    return value === Boolean(fieldValue);
  } else {
    return value === fieldValue;
  }
};

const HideableDependentField: React.FunctionComponent<Props> = ({ dependsOn, value = true, not = false, children }) => {
  const fieldValue = useWatch({ name: dependsOn });

  if ((!not && compareValues(value, fieldValue)) || (not && !compareValues(value, fieldValue))) {
    return <>{children}</>;
  } else {
    return null;
  }
};

HideableDependentField.displayName = 'HideableDependentField';

export default HideableDependentField;
