import { ProductField_Load } from '@asaprint/asap/components/forms/fields/ProductField.graphql';
import { ProductField_LoadQuery } from '@asaprint/asap/schema.client.types.js';
import { toOption } from '@asaprint/common/helpers/Product.js';
import { Option } from '@engined/client/components/forms/fields/AutocompleteField.js';
import RelationField, { Props as RelationFieldProps } from '@engined/client/components/forms/fields/RelationField.js';
import React from 'react';

interface OwnProps {}

type Props = OwnProps & Omit<RelationFieldProps<Option, any, any, any>, 'dataToOptions' | 'query' | 'variables'>;

const dataToOptions = (data: ProductField_LoadQuery) => data?.products?.edges.map((edge) => toOption(edge.node));

const ProductField: React.FunctionComponent<Props> = ({ name, ...rest }) => {
  return <RelationField {...rest} query={ProductField_Load} dataToOptions={dataToOptions} name={name} />;
};

ProductField.displayName = 'ProductField';

export default React.memo<Props>(ProductField);
